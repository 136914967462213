<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="活动名称">
              <el-input v-model="form.hdmc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公益活动appid">
              <el-input v-model="form.jumpAppid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跳转页面路径">
              <el-input v-model="form.jumpIndex"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效标志">
              <el-radio-group v-model="form.yxbz">
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动封面" prop="name">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                action="/api/image/uploadOss"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="form.hdfm" :src="form.hdfm" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动照片" prop="name">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :show-file-list="false"
                action="/api/image/uploadOss"
                :on-success="handleAvatarSuccess2"
              >
                <img v-if="form.hdzp" :src="form.hdzp" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动描述">
              <el-input
                type="textarea"
                :rows="3"
                v-model="form.hdms"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增公益活动",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      form: {
        hdfm: "",
        hdmc: "",
        hdms: "",
        hdzp: "",
        jumpAppid: "",
        jumpIndex: "",
        yxbz: "Y",
      },
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      this.form.hdfm = res.data;
    },
    handleAvatarSuccess2(res) {
      this.form.hdzp = res.data;
    },
    async submitData() {
      let params = { ...this.form };
      params.createTime = new Date().getTime();
      let url = "";
      if (params.gyhdId) {
        //修改
        url = "api/admin/home/gyhd/update";
      } else {
        url = "api/admin/home/gyhd/add";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增公益活动";
      this.form = {};
    },
    getInfo(item) {
      this.visible = true;
      this.dialogTitle = "编辑公益活动";
      this.form = JSON.parse(JSON.stringify(item));
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
